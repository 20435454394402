import { getHeaderMenu } from "@/functions/getHeaderMenu";
import classes from "./styles.module.scss";
import Link from "next/link";
import ArrowRight2 from "@/assets/ArrowRight2.svg";
import Dropdown from "@/assets/Dropdown.svg";
import { useState } from "react";

export default function MobileNav({ active, className }) {
  
  const [toggles, setToggles] = useState({});

  const nav = getHeaderMenu();

  function haveChild(item) {
    return item.children && item.children.length > 0
  }

  if (!active) return <></>;

  return (
    <div className={`${classes.nav_cont} ${className}`}>
      {
        nav.map((item, index) => {
        return <div key={index} className={`${classes.link} ${toggles[index] ? classes.active : ""}`}>
            {
              !haveChild(item) &&
              <Link href={item.url} className={`caption ${classes.link_text}`}>
                {item.title}
                {
                  haveChild(item) &&
                  <Dropdown width={16} height={16} />
                }
              </Link>
              
            }
            {
              haveChild(item) &&
              <>
                <div className={`caption ${classes.link_text} ${classes.dropdown}`} onClick={() => setToggles({ ...toggles, [index]: !toggles[index] })}>
                  {item.title}
                  {
                    haveChild(item) &&
                    <Dropdown width={16} height={16} />
                  }
                </div>
                <div className={classes.subCont}>
                  <div className={classes.list}>
                    {
                      item.children.map((child, j) => {
                        return <div className={`${classes.sublink}`} key={j}>
                          <Link href={child.url} className={`subtitle1`}>
                            {child.title}
                            <ArrowRight2 width={16} height={16} />
                          </Link>
                        </div>
                      })
                    }
                  </div>
                </div>
              </>
            }
          </div>
        })
      }
    </div>
  );
}