import PropTypes from "prop-types";
import classes from "./styles.module.scss";
import { getRoute } from "@/functions/getRoute";
import Link from "next/link";
import SiteHeaderNav from "./__components/SiteHeaderNav";
import Image from "../Image";
import { useEffect, useState } from "react";
import { NavbarToggleButton } from "./__components";
import { useRouter } from "next/router";
import MobileNav from "./__components/MobileNav";

export default function SiteHeader({ className }) {

  const router = useRouter();
  const [active, setActive] = useState(false);

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router])

  function toggle() {
    setActive(!active);
  }

  function handleRouteChange() {
    setActive(false);
  }

  return (
    <>
      <div className={`${classes.header}`}>
        <div className={classes.container}>
          <Link className={classes.logo} href={getRoute("home")}>
            {/* <Image img="/images/insight-logo.svg" size="148x45" alt="footer pattern" className={classes.pattern} /> */}
            <img src="/images/insight-logo.svg" alt="footer pattern" className={classes.pattern} />
          </Link>
          <div className={classes.links}>
            <SiteHeaderNav className={classes.navlink_cont} />
            <Link className={classes.contact} href="/contact-us">
              <div className={classes.desc}>CONTACT US</div>
            </Link>
          </div>
          <div className={classes.toggle}>
            <NavbarToggleButton active={active} onClick={toggle} />
          </div>
          <MobileNav active={active} />
        </div>
      </div>
    </>
  );
}

SiteHeader.defaultProps = {
  className: "",
};

SiteHeader.propTypes = {
  className: PropTypes.string,
};
