import { getHeaderMenu } from "@/functions/getHeaderMenu";
import classes from "./styles.module.scss";
import Link from "next/link";
import ArrowRight2 from "@/assets/ArrowRight2.svg";
import Dropdown from "@/assets/Dropdown.svg";

export default function SiteHeaderNav({ className }) {
  
  const nav = getHeaderMenu();

  function haveChild(item) {
    return item.children && item.children.length > 0
  }

  return (
    <div className={`${classes.nav_cont} ${className}`}>
      {
        nav.map((item, index) => {
          return <div key={index} className={classes.link}>
            <Link href={item.url} className={`caption`}>
              {item.title}
              {
                haveChild(item) &&
                <Dropdown width={16} height={16} />
              }
            </Link>
            {
              haveChild(item) &&
              <div className={classes.subCont}>
                <div className={classes.list}>
                  {
                    item.children.map((child, j) => {
                      return <div className={`${classes.sublink}`} key={j}>
                        <Link href={child.url} className={`subtitle1`}>
                          <span className={classes.sublink_text}>{child.title}</span>
                          <div className={classes.icon}><ArrowRight2 width={16} height={16} /></div>
                        </Link>
                      </div>
                    })
                  }
                </div>
              </div>
            }
          </div>
        })
      }
    </div>
  );
}